import type { IPage } from './guards/isContentPage';
import type { GetContentType } from '~/types/GetContentType';

export const pageToDefaultThemeMap: Record<
  GetContentType<IPage>,
  'dark' | 'light'
> = {
  contentPage: 'dark',
  contentPageMultiLocale: 'dark',
  productSeriesPage: 'dark',
  solutionsArticlePage: 'light'
};
